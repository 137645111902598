import React from 'react';
import styles from './styles.module.scss'; // Assuming shared or dedicated styling

const CompletionScreen = () => (
  <>
    <div className={styles.topBar}>
        <a href="https://www.feathery.io/">
        <img src="/logo-black.svg" alt="Logo" className={styles.logo} />
        </a>
    </div>
    <div className={styles.completionScreen}>
      <h1 className={styles.title}>Thank you for signing!</h1>
      <p className={styles.subtitle}>
        You’ll receive a copy in your inbox shortly.
      </p>
      <div className={styles.image}>
        <img src="/completion_icon.svg" alt="Completed" />
      </div>
      <div className={styles.greySection}>
        <p className={styles.text1}>
          Get your own documents signed for free
        </p>
        <p className={styles.text2}>
          <a href="https://app.feathery.io/#Sign%20Up">Sign up</a> for Feathery. Get up to unlimited signature requests.
        </p>
      </div>
    </div>
  </>
);

export default CompletionScreen;