/**
 * Renders a text input or textarea depending on the size of the field.
 * Text inputs expand horizontally until they hit the edge of their parent.
 * Textareas do not expand.
 *
 * @param field - The field object containing information about the text field.
 * @param onComplete - Optional callback function to be called when the text field is completed.
 * @param outerRef - Ref object to be used to access the underlying DOM element.
 * @returns The rendered text input or textarea component.
 */
import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';

import { getFieldStyles } from '../../utils';
import { PDFFieldProps } from '../../types';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { calculateLineHeight } from '../../../../utils';

type TextProps = PDFFieldProps;

const Text = (
  { field, onComplete = () => {} }: TextProps,
  outerRef: Ref<HTMLElement>
) => {
  const [value, setValue] = useState(field.value ?? '');
  const [isMultiline, setIsMultiline] = useState<boolean>();
  const inputRef = useRef<HTMLTextAreaElement | HTMLInputElement>(null);
  useImperativeHandle(outerRef, () => inputRef.current!, []);

  useEffect(() => {
    if (isMultiline != null) return;
    setIsMultiline(isFieldMultiline(inputRef.current!, field));
  }, [field, isMultiline]);

  const handleChange = (e: any) => {
    const fitInput =
      Tag === 'textarea'
        ? fitTextArea(e.target)
        : fitTextInput(e.target, field);
    setValue(fitInput);
    onComplete(fitInput === '' ? undefined : fitInput);
  };

  const Tag = isMultiline ? 'textarea' : 'input';
  return (
    <Tag
      id={field.key}
      readOnly={field.properties?.read_only ?? false}
      disabled={field.properties?.read_only ?? false}
      ref={inputRef as any}
      type='text'
      className={classNames(styles.text, {
        [styles.required]: !field.value && field.required
      })}
      style={getFieldStyles(field)}
      value={value}
      onChange={handleChange}
    />
  );
};

export default forwardRef<HTMLElement, TextProps>(Text);

/**
 * Determines if the field should be rendered as a textarea or input
 * based on the height required to display two lines of text.
 *
 * @param element - The HTML element representing the field.
 * @param field - The field object containing information about the text field.
 * @returns A boolean value indicating whether the field should be rendered as a textarea or input.
 */
function isFieldMultiline(element: HTMLElement, field: PDF.PDFField): boolean {
  const computedStyle = window.getComputedStyle(element);

  const lineHeight = calculateLineHeight(computedStyle);

  const paddingTop = parseFloat(computedStyle.paddingTop);
  const paddingBottom = parseFloat(computedStyle.paddingBottom);
  const borderTop = parseFloat(computedStyle.borderTopWidth);
  const borderBottom = parseFloat(computedStyle.borderBottomWidth);

  const twoRowHeight =
    2 * lineHeight + paddingTop + paddingBottom + borderTop + borderBottom;

  return field.dimensions.height > Math.ceil(twoRowHeight);
}

/**
 * Fits the text if a textarea element by removing text until it fits without scrolling.
 *
 * @param element - The HTML textarea element.
 * @returns The updated value of the textarea element.
 */
function fitTextArea(element: HTMLTextAreaElement): string {
  const style = window.getComputedStyle(element);
  const paddingTop = parseFloat(style.getPropertyValue('padding-top'));
  const paddingBottom = parseFloat(style.getPropertyValue('padding-bottom'));
  const borderTop = parseFloat(style.getPropertyValue('border-top-width'));
  const borderBottom = parseFloat(
    style.getPropertyValue('border-bottom-width')
  );

  const extraHeight = paddingTop + paddingBottom + borderTop + borderBottom;

  // Temporarily hide the scrollbar
  const originalOverflow = element.style.overflowY;
  element.style.overflowY = 'hidden';

  while (element.scrollHeight - extraHeight > element.clientHeight) {
    element.value = element.value.slice(0, -1);
  }

  // Restore the original overflow value
  element.style.overflowY = originalOverflow;

  return element.value;
}

/**
 * Fits the text of an input element either by expanding the width up to the parent
 * or by removing characters until the text fits.
 *
 * @param element - The HTML input element.
 * @param field - The field object containing information about the text field.
 * @param leadPadding - The padding to be added in front of the text.
 * @returns The updated value of the input element.
 */
function fitTextInput(
  element: HTMLInputElement,
  field: PDF.PDFField,
  leadPadding = 10
): string {
  const minWidth = field.dimensions.width;

  // if the element has a parent, it can expand to the edge of the parent
  const parent = element.parentElement;

  const maxWidth = parent ? parent.clientWidth - element.offsetLeft : minWidth;

  // reset the scroll position and width to calculate the content width
  element.scrollTop = 0;
  element.scrollLeft = 0;
  element.style.setProperty('width', '0px');

  const computedStyle = window.getComputedStyle(element);
  const borderLeft = parseFloat(computedStyle.borderLeftWidth);
  const borderRight = parseFloat(computedStyle.borderRightWidth);
  const fitWidth = element.scrollWidth + borderLeft + borderRight;

  const doesTextFit = fitWidth <= maxWidth;

  if (doesTextFit) {
    // if text fits, set the width to the content width plus lead padding
    const newWidth = Math.min(
      Math.max(fitWidth + leadPadding, minWidth),
      maxWidth
    );
    element.style.setProperty('width', `${newWidth}px`);
  } else {
    while (element.scrollWidth + borderLeft + borderRight > maxWidth) {
      // remove characters until the text fits
      element.value = element.value.slice(0, -1);
    }
    const newWidth = Math.min(
      Math.max(
        element.scrollWidth + borderLeft + borderRight + leadPadding,
        minWidth
      ),
      maxWidth
    );
    element.style.setProperty('width', `${newWidth}px`);
  }

  return element.value;
}
