import styles from './styles.module.scss';

type ErrorPageProps = {
  title: string;
  message: string;
};

const ErrorPage = ({ title, message }: ErrorPageProps) => {
  return (
    <div className={styles.container}>
      <h1>{title}</h1>
      <p>{message}</p>
    </div>
  );
};

export default ErrorPage;
