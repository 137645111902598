import { forwardRef, Ref, useRef } from 'react';
import { CloseIcon } from '../Icons';
import styles from './styles.module.scss';
import classNames from 'classnames';
import Modal from './components/Modal';
import ReactPortal from './components/ReactPortal';

function Dialog(
  {
    className,
    children,
    size = 'sm',
    title,
    header,
    isOpen,
    onClose = () => {},
    shouldShowCloseIcon = false,
    alignCenter = false,
    style
  }: any,
  ref: Ref<HTMLDivElement>
) {
  const dialogRef = useRef(null);

  const handleDisClick = (e: any) => {
    if (dialogRef && e.target === dialogRef.current) {
      onClose();
    }
  };

  return (
    <ReactPortal>
      {isOpen && <div className={styles.backdrop} />}
      <div
        className={classNames(styles.dialogContainer, isOpen && styles.animate)}
        onClick={handleDisClick}
        ref={dialogRef}
      >
        {isOpen && (
          <Modal onClose={onClose}>
            <div
              ref={ref}
              style={style}
              className={classNames(
                styles.dialog,
                className,
                styles[`dialog-${size}`],
                alignCenter && 'text-center',
                className
              )}
            >
              {header && header(title)}
              {!header && title && (
                <h2 className={styles.dialogHeader}>{title}</h2>
              )}
              {children}
              {shouldShowCloseIcon && (
                <div
                  className={styles.dialogClose}
                  tabIndex={0}
                  onClick={onClose}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
          </Modal>
        )}
      </div>
    </ReactPortal>
  );
}

export default forwardRef(Dialog);
