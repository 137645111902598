import { forwardRef, Ref } from 'react';

import { PDFFieldProps } from './types';
import Signature from './fields/Signature';
import Text from './fields/Text';
import Checkbox from './fields/Checkbox';
import Combobox from './fields/Combobox';
import RadioButton from './fields/RadioButton';

const PDFField = (
  { field, onComplete }: PDFFieldProps,
  ref: Ref<HTMLElement>
) => {
  const { type } = field;
  switch (type) {
    case 'signature':
      return <Signature field={field} onComplete={onComplete} ref={ref} />;
    case 'text':
      return <Text field={field} onComplete={onComplete} ref={ref} />;
    case 'radio':
      return <RadioButton field={field} onComplete={onComplete} ref={ref} />;
    case 'checkbox':
      return <Checkbox field={field} onComplete={onComplete} ref={ref} />;
    case 'combobox':
    case 'listbox':
      return <Combobox field={field} onComplete={onComplete} ref={ref} />;
    default:
      return null;
  }
};

export default forwardRef<HTMLElement, PDFFieldProps>(PDFField);
