import { request } from './http';
import { Document } from '../../src/context/DocumentProvider/types';

const authHeaders = (envelopeId: string) => {
  return {
    Authorization: `Token ${envelopeId}`
  };
};

export const fetchDocuments = async (envelopeId: string): Promise<any[]> => {
  let retries = 0;

  while (retries < 3) {
    const response = await request('/api/document/list/', {
      headers: authHeaders(envelopeId)
    });

    if (response && response.length > 0) {
      return response; // Return if the response is not empty
    } else {
      retries++;
      if (retries < 3) {
        // If it's not the last attempt, wait for 5 seconds before retrying
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    }
  }

  return []; // Return empty list if all attempts are empty
};

export const postDocument = async (currentDocument: Document, pdf: Blob) => {
  const fileName = currentDocument.name;
  const envelopeId = currentDocument.id;
  const pdfFile = new File([pdf], fileName, { type: 'application/pdf' });
  const formData = new FormData();
  formData.append('file', pdfFile);

  return request('/api/document/', {
    method: 'POST',
    headers: {
      ...authHeaders(envelopeId)
    },
    body: formData
  });
};

export const fetchDocumentVerification = async (
  envelopeId: string,
  consent: boolean,
  email?: string
) => {
  let url = `/api/document/verify/?consent=${encodeURIComponent(consent)}`;
  if (email) {
    url += `&email=${encodeURIComponent(email)}`;
  }

  return request(url, {
    headers: authHeaders(envelopeId)
  });
};
