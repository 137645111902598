import { Routes, Route } from 'react-router-dom';
import { DocumentPage } from './pages/Document';
import ErrorPage from './pages/Error';

const App = () => {
  return (
    <Routes>
      <Route path='/to/:envelopeId' element={<DocumentPage />} />
      <Route
        path='/expired'
        element={
          <ErrorPage
            title='Document Expired'
            message='This document is expired and can no longer be viewed or signed.'
          />
        }
      />
      <Route
        path='*'
        element={
          <ErrorPage
            title='Not Found'
            message='The page you requested does not exist.'
          />
        }
      />
    </Routes>
  );
};

export default App;
