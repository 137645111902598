/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

type ModalProps = React.PropsWithChildren<{
  onClose?: () => void;
}>;

const Modal = ({ children, onClose = () => {} }: ModalProps) => {
  useEffect(() => {

    const handleKeyEvent = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keyup', handleKeyEvent);

    return () => {
      document.removeEventListener('keyup', handleKeyEvent);
    };
  }, []);

  return <>{children}</>;
};

export default Modal;
