import * as Actions from './actions';
import { Action } from '../types';
import { DocumentState } from './types';

export const reducer = (
  state: DocumentState,
  action: Action
): DocumentState => {
  const { type, payload = {} } = action;

  switch (type) {
    case Actions.FETCH_DOCUMENT:
      return {
        ...state,
        ...payload
      };

    case Actions.UPDATE_DOCUMENT_INDEX:
      return {
        ...state,
        currentIndex: payload
      };

    case Actions.FETCH_DOCUMENT_VERIFICATION:
      return {
        ...state,
        verified: payload
      };

    case Actions.SET_HAS_SIGNED:
      const updatedDocuments = [...state.documents];
      updatedDocuments[state.currentIndex] = {
        ...updatedDocuments[state.currentIndex],
        hasSigned: payload
      };

      return {
        ...state,
        documents: updatedDocuments
      };

    case Actions.SET_FIELD_VALUE:
      const updatedFields = state.documents[state.currentIndex].fields.map(
        (field) => {
          if (field.key === payload.key) {
            return {
              ...field,
              value: payload.value
            };
          }
          return field;
        }
      );

      return {
        ...state,
        documents: state.documents.map((document, index) => {
          if (index === state.currentIndex) {
            return {
              ...document,
              fields: updatedFields
            };
          }
          return document;
        })
      };

    case Actions.SET_RADIO_GROUP:
      const updatedGroups = {
        ...state.radioGroups,
        [payload.group]: payload.value
      };

      return {
        ...state,
        radioGroups: updatedGroups
      };

    default:
      return state;
  }
};
