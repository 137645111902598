import { forwardRef, Ref } from 'react';

import { getFieldStyles } from '../../utils';
import { PDFFieldProps } from '../../types';
import styles from './styles.module.scss';
import classNames from 'classnames';

type CheckboxProps = PDFFieldProps;

const Checkbox = (
  { field, onComplete = () => {} }: CheckboxProps,
  ref: Ref<HTMLElement>
) => {
  const handleChange = (e: any) => {
    const isChecked = e.target.checked;
    onComplete(isChecked);
  };

  return (
    <label className={styles.checkbox} style={getFieldStyles(field)}>
      <input
        id={field.key}
        name={field.properties?.radio_group || undefined}
        ref={ref as any}
        type='checkbox'
        onChange={handleChange}
        checked={field.value ?? false}
      />
      <span
        className={classNames(styles.checkmark, {
          [styles.required]: !field.value && field.required
        })}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
        >
          <path d='M20 6 9 17l-5-5' />
        </svg>
      </span>
    </label>
  );
};

export default forwardRef<HTMLElement, CheckboxProps>(Checkbox);
