import { PropsWithChildren, useState } from 'react';
import { useDocumentState, useDocumentUpdater } from '../../context';
import classNames from 'classnames';
import styles from './styles.module.scss';

type VerificationProps = PropsWithChildren<{
  envelopeId: string;
}>;

const Verification = ({ children }: VerificationProps) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [consentCheck, setConsentCheck] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [errors, setErrors] = useState<any>({});
  const verified = useDocumentState((s) => s.verified);
  const fetchDocumentVerification = useDocumentUpdater(
    (s) => s.fetchDocumentVerification
  );
  const currentIndex = useDocumentState((s) => s.currentIndex);
  const documents = useDocumentState((s) => s.documents);
  const omitVerifyEmail = documents.every((doc) => !doc.verifyEmail);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailAddress(e.target.value);
  };

  const handleSubmit = async () => {
    setErrMessage('');
    setErrors({});

    if (!omitVerifyEmail && !emailAddress) {
      setErrMessage('Please enter an email address.');
      setErrors({ email: true });
      return;
    }

    if (!consentCheck) {
      setErrMessage('Please agree to the terms.');
      return;
    }

    try {
      let currentDocumentEnvelopeID = documents[currentIndex].id;
      const value = await fetchDocumentVerification(
        currentDocumentEnvelopeID,        
        consentCheck,
        omitVerifyEmail ? '' : emailAddress
      );

      if (!value) {
        setErrMessage('Failed to authenticate.');
      }
    } catch (err: any) {
      setErrMessage(err?.message ?? 'Failed to authenticate.');
    }
  };

  if (verified) {
    return <>{children}</>;
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <h2>
          {omitVerifyEmail 
            ? 'Consent Required'
            : 'Verification Required'
          }
          </h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              return handleSubmit();
            }}
          >
            {!omitVerifyEmail &&
              <input
                type='email'
                className={classNames({ [styles.error]: errors.email })}
                placeholder='Signer email'
                value={emailAddress}
                onChange={handleChange}
              />
            }
            <div className={styles.consent}>
              <label className={styles.consentCheck}>
                I agree that the signature and initials will be the electronic
                representation of my signature and initials for all purposes
                when I (or my agent) use them on documents, including legally
                binding contracts.
                <input
                  type='checkbox'
                  id='consent'
                  name='consent'
                  checked={consentCheck}
                  onChange={() => setConsentCheck(!consentCheck)}
                />
                <span className={styles.checkmark}></span>
              </label>
            </div>
            {errMessage && <div className={styles.alert}>{errMessage}</div>}
            <button type='submit' className={styles.continueButton}>
              Continue
            </button>
          </form>
        </div>
      </div>
      {children}
    </>
  );
};

export default Verification;
