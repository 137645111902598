import { forwardRef, Ref } from 'react';

import { getFieldStyles } from '../../utils';
import { PDFFieldProps } from '../../types';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { useDocumentState, useDocumentUpdater } from '../../../../context';
import { isRadioButtonGroupFilled } from '../../../../utils';

type RadioButtonProps = PDFFieldProps;

const RadioButton = (
  { field, onComplete = () => {} }: RadioButtonProps,
  ref: Ref<HTMLElement>
) => {
  const isFilled = useDocumentState(
    (s) => s.radioGroups[field.properties?.radio_group]
  );
  const setRadioGroup = useDocumentUpdater((s) => s.setRadioGroup);

  const handleChange = (e: any) => {
    const isChecked = isRadioButtonGroupFilled(field.properties?.radio_group);

    onComplete(isChecked);
    setRadioGroup(field.properties?.radio_group, isChecked);
  };

  return (
    <label className={styles.radiobutton} style={getFieldStyles(field)}>
      <input
        id={field.key}
        name={field.properties?.radio_group || field.key}
        ref={ref as any}
        type='radio'
        onChange={handleChange}
      />
      <span
        className={classNames(styles.checkmark, {
          [styles.required]: !isFilled && field.required
        })}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
        >
          <path d='M20 6 9 17l-5-5' />
        </svg>
      </span>
    </label>
  );
};

export default forwardRef<HTMLElement, RadioButtonProps>(RadioButton);
