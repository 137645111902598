export const getFieldStyles = (field: PDF.PDFField) => {
  return {
    top: `${field.position.y}px`,
    left: `${field.position.x}px`,
    width: `${field.dimensions.width}px`,
    height: `${field.dimensions.height}px`,
    ...createTypographyStyles(field)
  };
};

const createTypographyStyles = (field: PDF.PDFField) => {
  const {
    font_size = 14,
    font_color = '000000',
    font_family = 'Helvetica',
    font_weight = 400
  } = field.styles ?? {};
  return {
    fontSize: `${font_size}px`,
    color: `#${font_color}`,
    fontFamily: `'${font_family}', 'Arial', sans-serif`,
    fontWeight: font_weight
  };
};
