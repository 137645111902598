import { useEffect, useState } from 'react';
import { PDFDocument } from 'pdf-lib';

export const usePdf = (url: string) => {
  const [pdfDoc, setPdfDoc] = useState<PDFDocument>();

  useEffect(() => {
    if (url) {
      fetch(url)
        .then((res) => res.arrayBuffer())
        .then((buffer) => PDFDocument.load(buffer))
        .then((doc) => setPdfDoc(doc));
    }
  }, [url]);

  return pdfDoc;
};
