import { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { PDF_PAGE_WIDTH } from '../../constants';
import PDFField from '../PDFField';
import styles from './styles.module.scss';

type PDFDocumentProps = {
  pdf: PDF.PDFDocument;
  onFieldChange?: (key: string, value: any) => void;
  focusFirstRequiredField?: boolean;
  onFieldFocused?: () => void;
};

const PDFDocument = (props: PDFDocumentProps) => {
  const {
    pdf,
    onFieldChange = () => {},
    focusFirstRequiredField = false,
    onFieldFocused = () => {}
  } = props;
  const [numPages, setNumPages] = useState(0);

  // map of required fields to their elements
  const [requiredFieldMap, setRequiredFieldMap] = useState<{
    [key: string]: HTMLElement;
  }>({});

  const mapRequiredFieldElement = (el: HTMLElement, field: PDF.PDFField) => {
    if (el && el !== requiredFieldMap[field.key] && field.required) {
      setRequiredFieldMap((requiredFieldMap) => {
        return { ...requiredFieldMap, [field.key]: el };
      });
    }
  };

  function offsetY(el: HTMLElement) {
    var rect = el.getBoundingClientRect(),
      scrollTop = window.scrollY || document.documentElement.scrollTop;
    return rect.top + scrollTop;
  }

  const sortByPosition = (a: any, b: any) => {
    // Sort primarily by the vertical position (y)
    if (a.position.y !== b.position.y) {
      return a.position.y - b.position.y;
    }
    // If vertical positions are the same, sort by the horizontal position (x)
    return a.position.x - b.position.x;
  };

  useEffect(() => {
    if (focusFirstRequiredField) {
      const unfilledRequiredFields = pdf.fields.filter(
        (field: PDF.PDFField) => field.required && field.value === undefined
      );
      // find the first one vertically
      const firstUnfilledRequiredField = unfilledRequiredFields.reduce(
        (first, field) => {
          const el = requiredFieldMap[field.key];
          if (!first) return { el, y: offsetY(el) };
          const y = offsetY(el);
          if (y < first.y) return { el, y };
          return first;
        },
        null
      );

      // scroll to the first unfilled required field at top of the viewport
      if (firstUnfilledRequiredField) {
        const elementPosition =
          firstUnfilledRequiredField.el.getBoundingClientRect().top +
          window.scrollY;
        const offsetPosition = elementPosition - 60;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
        firstUnfilledRequiredField.el.focus?.();
        onFieldFocused();
      }
    }
  }, [pdf.fields, requiredFieldMap, focusFirstRequiredField, onFieldFocused]);

  return (
    <Document
      className={styles.pdfDocument}
      file={pdf.file}
      onLoadSuccess={(d: any) => {
        setNumPages(d._pdfInfo.numPages);
      }}
    >
      {new Array(numPages).fill(0).map((_, index) => (
        <Page
          className={styles.pdfPage}
          key={`page_${index}`}
          width={PDF_PAGE_WIDTH}
          pageNumber={index + 1}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        >
          {pdf.fields
            .filter((f: any) => f.page === index)
            .sort(sortByPosition)
            .map((field: any) => (
              <PDFField
                key={field.key}
                ref={(el: HTMLElement) => mapRequiredFieldElement(el, field)}
                field={field}
                onComplete={(value: any) => onFieldChange(field.key, value)}
              />
            ))}
        </Page>
      ))}
    </Document>
  );
};

export default PDFDocument;
