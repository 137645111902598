import type { Dispatch } from 'react';
import { mapAPIDocumentToDocument } from './utils';
import { APIDocument } from './types';

import {
  fetchDocuments as _fetchDocuments,
  fetchDocumentVerification as _fetchDocumentVerification
} from '../../api/document';

/**
 * Action type definitions
 */
export const FETCH_DOCUMENT = 'FETCH_DOCUMENT';
export const SET_HAS_SIGNED = 'SET_HAS_SIGNED';
export const SET_FIELD_VALUE = 'SET_FIELD_VALUE';
export const FETCH_DOCUMENT_VERIFICATION = 'FETCH_DOCUMENT_VERIFICATION';
export const UPDATE_DOCUMENT_INDEX = 'UPDATE_DOCUMENT_INDEX';
export const SET_RADIO_GROUP = 'SET_RADIO_GROUP';

const fetchDocument =
  (dispatch: Dispatch<any>) => async (envelopeId: string) => {
    const documents = await _fetchDocuments(envelopeId);
    let currentIndex = 0;
    while (documents[currentIndex].has_signed) {
      currentIndex = currentIndex + 1;
    }
    documents.forEach((doc) => {
      Object.entries(doc.fonts ?? {}).forEach(([family, fontStyles]) => {
        ((fontStyles as any) ?? []).forEach(({ source, style, weight }: any) =>
          new FontFace(family, `url(${source})`, {
            style,
            weight
          })
            .load()
            .then((font) => document.fonts.add(font))
            .catch((e) => console.warn(e))
        );
      });
    });

    const transformedDocuments = documents.map((document: APIDocument) =>
      mapAPIDocumentToDocument(document)
    );

    dispatch({
      type: FETCH_DOCUMENT,
      payload: {
        documents: transformedDocuments,
        currentIndex: currentIndex,
        loaded: true
      }
    });
  };

const updateDocumentIndex = (dispatch: Dispatch<any>) => (newIndex: number) => {
  dispatch({
    type: UPDATE_DOCUMENT_INDEX,
    payload: newIndex
  });
};

const setHasSigned = (dispatch: Dispatch<any>) => (hasSigned: boolean) => {
  dispatch({
    type: SET_HAS_SIGNED,
    payload: hasSigned
  });
};

const setFieldValue =
  (dispatch: Dispatch<any>) => (key: string, value: any) => {
    dispatch({
      type: SET_FIELD_VALUE,
      payload: {
        key,
        value
      }
    });
  };

const setRadioGroup =
  (dispatch: Dispatch<any>) => (group: string, value: boolean) => {
    dispatch({
      type: SET_RADIO_GROUP,
      payload: {
        group,
        value
      }
    });
  };

const fetchDocumentVerification =
  (dispatch: Dispatch<any>) =>
  async (envelopeId: string, consent: boolean, email?: string) => {
    const { valid } = await _fetchDocumentVerification(
      envelopeId,
      consent,
      email
    );

    dispatch({
      type: FETCH_DOCUMENT_VERIFICATION,
      payload: valid
    });

    return valid;
  };

export const actions = {
  fetchDocument,
  setFieldValue,
  setRadioGroup,
  setHasSigned,
  fetchDocumentVerification,
  updateDocumentIndex
};
