import { forwardRef, Ref } from 'react';

import { getFieldStyles } from '../../utils';
import { PDFFieldProps } from '../../types';
import { Elements } from '@feathery/react';
import ReactPortal from '../../../Dialog/components/ReactPortal';
import styles from './styles.module.scss';

type SignatureProps = PDFFieldProps;

const Signature = (
  { field, onComplete = () => {} }: SignatureProps,
  ref: Ref<any>
) => {
  const handleEnd = (file: any) => {
    onComplete(file);
  };

  const clearSignature = () => {
    onComplete(undefined);
  };

  return (
    <div
      className={styles.signatureImage}
      style={getFieldStyles(field)}
      ref={ref}
    >
      <Elements.SignatureField
        ReactPortal={ReactPortal}
        style={{ maxHeight: '100%' }}
        defaultValue={field.value}
        onEnd={handleEnd}
        onClear={clearSignature}
        element={{
          servar: {
            type: 'signature',
            key: field.key,
            metadata: {
              sign_methods: field.properties?.sign_methods
            }
          },
          styles: {
            height: field.dimensions.height,
            height_unit: 'px',
            background_color:
              !field.value && field.required ? 'e2626e20' : 'transparent',
            border_bottom_color: 'e2626eff',
            border_bottom_pattern: 'solid',
            border_bottom_width: 1,
            border_left_color: 'e2626eff',
            border_left_pattern: 'solid',
            border_left_width: 1,
            border_right_color: 'e2626eff',
            border_right_pattern: 'solid',
            border_right_width: 1,
            border_top_color: 'e2626eff',
            border_top_pattern: 'solid',
            border_top_width: 1
          },
          properties: {}
        }}
      />
    </div>
  );
};

export default forwardRef<any, SignatureProps>(Signature);
