import { Document } from './types';
import { v4 as uuidv4 } from 'uuid';
import { APIDocument } from './types';

export const mapAPIDocumentToDocument = (
  apiDocument: APIDocument
): Document => {
  return {
    type: 'pdf',
    file: apiDocument.file,
    name: apiDocument.name,
    id: apiDocument.id,
    hasSigned: apiDocument.signed,
    isExpired: apiDocument.expired ?? false,
    verifyEmail: apiDocument.verify_email,
    fonts: apiDocument.fonts,
    fields: apiDocument.annotated_fields.map((field) => {
      return {
        key: uuidv4(),
        page: field.page,
        type: field.type,
        position: {
          x: field.x,
          y: field.y
        },
        dimensions: {
          width: field.width,
          height: field.height
        },
        isMapped: !!field.form_field_id,
        value: field.properties?.default_value ?? undefined,
        required: field.required,
        properties: field.properties,
        styles: field.styles
      } as PDF.PDFField;
    })
  };
};
