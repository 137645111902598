import { Action, ActionFn } from './types';

/**
 * connect
 * Wrap the action functions within an object with the dispatch function
 *
 * @param dispatch The dispatch being used by the reducer
 */
export const connect =
  <T>(dispatch: React.Dispatch<Action>) =>
  (actions: { [key: string]: ActionFn }): T => {
    return Object.keys(actions).reduce((wrappedActions, currentAction) => {
      return {
        ...wrappedActions,
        [currentAction]: actions[currentAction](dispatch)
      };
    }, {} as T);
  };
