import { PDFDocument, PDFFont, grayscale, rgb } from 'pdf-lib';
import { PDF_PAGE_WIDTH } from '../../constants';
import { calculateLineHeight, calcRgbPercentages } from '../../utils';

export function sortByPosition(a: PDF.PDFField, b: PDF.PDFField) {
  if (a.page === b.page) {
    return a.position.y - b.position.y || a.position.x - b.position.x;
  }
  return a.page - b.page;
}

export function drawText(
  field: PDF.PDFField,
  pdf: PDFDocument,
  fonts: Record<string, PDFFont>
) {
  let isTextArea = false;
  let input: HTMLInputElement | HTMLTextAreaElement | null =
    document.querySelector<HTMLInputElement>(`input[id="${field.key}"]`);
  if (!input) {
    input = document.querySelector<HTMLTextAreaElement>(
      `textarea[id="${field.key}"]`
    );
    isTextArea = true;
  }

  if (input && field.value) {
    const page = pdf.getPage(field.page);
    const pageHeight = page.getHeight();
    const pageWidth = page.getWidth();
    const pageScale = pageWidth / PDF_PAGE_WIDTH;

    const styles = getComputedStyle(input);
    const inputFontSize = Number.parseFloat(styles.fontSize.split('px')[0]);
    const inputHeight = Number.parseFloat(styles.height.split('px')[0]);
    const inputWidth = Number.parseFloat(styles.width.split('px')[0]);
    const inputOffset = inputHeight + (inputHeight - inputFontSize) / 2;

    const lineHeight = calculateLineHeight(styles);

    const font = styles.fontFamily.split(',')[0].replace(/['"]/g, '');
    const weight = styles.fontWeight;

    const embeddedFont = fonts[`${font}-${weight}`] ?? undefined;

    page.drawText(field.value, {
      x: field.position.x * pageScale,
      y:
        pageHeight -
        field.position.y * pageScale -
        (isTextArea
          ? inputFontSize * pageScale
          : (inputOffset / 2) * pageScale),
      lineHeight: isTextArea ? lineHeight * pageScale : inputHeight * pageScale,
      size: inputFontSize * pageScale,
      maxWidth: inputWidth * pageScale,
      color: rgb(...calcRgbPercentages(styles.color)),
      font: embeddedFont,
      wordBreaks: ['']
    });

    pdf.save();
  }
}

export function drawCombobox(
  field: PDF.PDFField,
  pdf: PDFDocument,
  fonts: Record<string, PDFFont>
) {
  let select: HTMLInputElement | HTMLTextAreaElement | null =
    document.querySelector<HTMLInputElement>(`select[id="${field.key}"]`);

  if (select && field.value) {
    const page = pdf.getPage(field.page);
    const pageHeight = page.getHeight();
    const pageWidth = page.getWidth();
    const pageScale = pageWidth / PDF_PAGE_WIDTH;

    const styles = getComputedStyle(select);
    const inputFontSize = Number.parseFloat(styles.fontSize.split('px')[0]);
    const inputHeight = Number.parseFloat(styles.height.split('px')[0]);
    const inputWidth = Number.parseFloat(styles.width.split('px')[0]);
    const inputOffset = inputHeight + (inputHeight - inputFontSize) / 2;

    const font = styles.fontFamily.split(',')[0].replace(/'/g, '');
    const weight = styles.fontWeight;

    const embeddedFont = fonts[`${font}-${weight}`] ?? undefined;

    page.drawText(field.value, {
      x: field.position.x * pageScale,
      y:
        pageHeight -
        field.position.y * pageScale -
        (inputOffset / 2) * pageScale,
      lineHeight: inputHeight * pageScale,
      size: inputFontSize * pageScale,
      maxWidth: inputWidth * pageScale,
      color: rgb(...calcRgbPercentages(styles.color)),
      font: embeddedFont,
      wordBreaks: ['']
    });

    pdf.save();
  }
}

export function drawSignature(field: PDF.PDFField, pdf: PDFDocument) {
  const signature = document.querySelector<HTMLCanvasElement>(
    `canvas[id="${field.key}"]`
  );

  if (signature && field.value) {
    const signatureImg = signature.toDataURL('image/png');

    pdf.embedPng(signatureImg).then((image) => {
      const page = pdf.getPage(field.page);
      const pageHeight = page.getHeight();
      const pageWidth = page.getWidth();
      const pageScale = pageWidth / PDF_PAGE_WIDTH;

      const imgWidth = field.dimensions.width * pageScale;
      const imgHeight = field.dimensions.height * pageScale;

      page.drawImage(image, {
        x: field.position.x * pageScale,
        y: pageHeight - field.position.y * pageScale - imgHeight,
        width: imgWidth,
        height: imgHeight
      });

      pdf.save();
    });
  }
}

const CHECK_SVG = {
  path: 'M20 6 9 17l-5-5',
  size: 24,
  strokeWidth: 4,
  strokeColor: grayscale(1)
};

const SQUARE_SVG = {
  path: 'M 0 3 C 0 1.3432 1.3432 0 3 0 H 15 C 16.6569 0 18 1.3432 18 3 V 15.3149 C 18 16.8435 16.8506 18.1277 15.3313 18.2965 L 2.6687 18.2965 C 1.1494 18.1277 0 16.8435 0 15.3149 V 3 Z',
  size: 18
};

const CHECKBOX_SIZE = 16;

export function drawCheckbox(field: PDF.PDFField, pdf: PDFDocument) {
  let input: HTMLInputElement | HTMLTextAreaElement | null =
    document.querySelector<HTMLInputElement>(`input[id="${field.key}"]`);
  if (input) {
    const page = pdf.getPage(field.page);
    const pageHeight = page.getHeight();
    const pageWidth = page.getWidth();
    const pageScale = pageWidth / PDF_PAGE_WIDTH;

    const isRadio = field.type === 'radio';
    const isChecked = input?.checked;

    if (isChecked) {
      if (isRadio) {
        const circleRadius = CHECKBOX_SIZE / 2;
        page.drawCircle({
          x: field.position.x * pageScale + circleRadius,
          y: pageHeight - field.position.y * pageScale - circleRadius,
          size: circleRadius,
          color: rgb(0.886, 0.384, 0.431),
          borderWidth: 1,
          borderColor: rgb(0.886, 0.384, 0.431)
        });
        page.drawSvgPath(CHECK_SVG.path, {
          x: field.position.x * pageScale,
          y: pageHeight - field.position.y * pageScale,
          borderColor: grayscale(1),
          borderWidth: CHECK_SVG.strokeWidth,
          scale: CHECKBOX_SIZE / CHECK_SVG.size
        });
      } else {
        page.drawSvgPath(SQUARE_SVG.path, {
          x: field.position.x * pageScale,
          y: pageHeight - field.position.y * pageScale,
          color: rgb(0.886, 0.384, 0.431),
          borderWidth: 1,
          borderColor: rgb(0.886, 0.384, 0.431),
          scale: CHECKBOX_SIZE / SQUARE_SVG.size
        });
        page.drawSvgPath(CHECK_SVG.path, {
          x: field.position.x * pageScale,
          y: pageHeight - field.position.y * pageScale,
          borderColor: grayscale(1),
          borderWidth: CHECK_SVG.strokeWidth,
          scale: CHECKBOX_SIZE / CHECK_SVG.size
        });
      }
    } else {
      if (isRadio) {
        const circleRadius = CHECKBOX_SIZE / 2;
        page.drawCircle({
          x: field.position.x * pageScale + circleRadius,
          y: pageHeight - field.position.y * pageScale - circleRadius,
          size: circleRadius,
          color: grayscale(0.933),
          borderWidth: 1,
          borderColor: grayscale(0.733)
        });
      } else {
        page.drawSvgPath(SQUARE_SVG.path, {
          x: field.position.x * pageScale,
          y: pageHeight - field.position.y * pageScale,
          color: grayscale(0.933),
          borderWidth: 1,
          borderColor: grayscale(0.733),
          scale: CHECKBOX_SIZE / SQUARE_SVG.size
        });
      }
    }
    pdf.save();
  }
}
