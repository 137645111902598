import { forwardRef, Ref } from 'react';

import { getFieldStyles } from '../../utils';
import { PDFFieldProps } from '../../types';
import styles from './styles.module.scss';
import classNames from 'classnames';

type ComboboxProps = PDFFieldProps;

const Combobox = (
  { field, onComplete = () => {} }: ComboboxProps,
  ref: Ref<HTMLElement>
) => {
  const handleChange = (e: any) => {
    onComplete(e.target.value);
  };

  const options = (field.properties?.options ?? []).map(
    (option: string, index: number) => (
      <option key={`${option}_${index}`} value={option}>
        {option}
      </option>
    )
  );

  return (
    <select
      id={field.key}
      ref={ref as any}
      className={classNames(styles.combobox, {
        [styles.required]: !field.value && field.required
      })}
      style={getFieldStyles(field)}
      onChange={handleChange}
      defaultValue={field.value ?? ''}
    >
      {/* no value option for optional dropdown */}
      <option disabled={field.required} value=''>
        {' '}
        -- select an option --{' '}
      </option>
      {options}
    </select>
  );
};

export default forwardRef<HTMLElement, ComboboxProps>(Combobox);
