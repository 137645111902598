import { PropsWithChildren, useMemo, useReducer } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { DocumentState, DocumentUpdater } from './types';
import { reducer } from './reducer';
import { actions } from './actions';
import { connect } from '../utils';

/**
 * Initial states
 */
export const DEFAULT_STATE: DocumentState = {
  documents: [
    {
      type: 'pdf',
      file: '',
      hasSigned: false,
      isExpired: false,
      fields: [],
      id: '',
      name: '',
      verifyEmail: true,
      fonts: []
    }
  ],
  verified: false,
  currentIndex: 0,
  loaded: false,
  radioGroups: {}
};

export const DEFAULT_UPDATER: DocumentUpdater = {
  fetchDocument: () => {},
  fetchDocumentVerification: () => false,
  setHasSigned: () => {},
  setFieldValue: () => {},
  updateDocumentIndex: () => {},
  setRadioGroup: () => {}
};

/**
 * Context definitions
 */
export const DocumentStateContext = createContext<DocumentState>(DEFAULT_STATE);
export const DocumentUpdaterContext =
  createContext<DocumentUpdater>(DEFAULT_UPDATER);

/**
 * Hook definitions
 */
export function useDocumentState<T>(fn: (value: DocumentState) => T): T {
  return useContextSelector(DocumentStateContext, fn);
}

export function useDocumentUpdater<T>(fn: (value: DocumentUpdater) => T): T {
  return useContextSelector(DocumentUpdaterContext, fn);
}

/**
 * Define provider
 */
export const DocumentProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);
  const connectFns = useMemo(() => connect(dispatch), [dispatch]);

  const stateValue: DocumentState = useMemo(() => ({ ...state }), [state]);
  const updaterValue: DocumentUpdater = useMemo(
    () => connectFns(actions) as DocumentUpdater,
    [connectFns]
  );

  return (
    <DocumentStateContext.Provider value={stateValue}>
      <DocumentUpdaterContext.Provider value={updaterValue}>
        {children}
      </DocumentUpdaterContext.Provider>
    </DocumentStateContext.Provider>
  );
};
